<template>
  <v-tabs>
    <v-tabs-slider></v-tabs-slider>
    <v-tab>センサ検知ログ</v-tab>
    <v-tab>LINE送信ログ</v-tab>
    <v-tab>メール送信ログ</v-tab>
    <v-tab-item>
      <v-data-table
        class="elevation-0"
        :headers="deviceAlertHeaders"
        :items="deviceAlertLogs"
        :loading="loading"
      >
        <template v-slot:[`item.timestamp`]="{ item }">
          {{ item.timestamp | moment }}
        </template>
        <template v-slot:[`item.send_type`]="{ item }">
          {{ item.send_type | sendTypeLabel }}
        </template>
        <template v-slot:[`item.device_alert_type`]="{ item }">
          {{ item.device_alert_type | deviceAlertTypeTypeLabel }}
        </template>
      </v-data-table>
    </v-tab-item>
    <v-tab-item>
      <v-data-table
        class="elevation-0"
        :headers="notificationMessageHeadersForLine"
        :items="notificationMessageLogsForLine"
        :loading="loading"
      >
        <template v-slot:[`item.timestamp`]="{ item }">
          {{ item.timestamp | moment }}
        </template>
        <template v-slot:[`item.users`]="{ item }">
          <v-btn v-if="item.users != ''" icon @click="oepnMessage(item.users)">
            <v-icon small>mdi-account</v-icon>
          </v-btn>
        </template>
        <template v-slot:[`item.message`]="{ item }">
          <v-btn icon @click="oepnMessage(item.message)">
            <v-icon small>mdi-note</v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <v-dialog
        v-model="messageDialog"
        max-width="500"
        @click:outside="messageClose"
      >
        <v-card>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <p style="white-space:pre-wrap;" v-text="message"></p>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn depressed @click="messageClose">
              閉じる
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-tab-item>
    <v-tab-item>
      <v-data-table
        class="elevation-0"
        :headers="notificationMessageHeadersForEmail"
        :items="notificationMessageLogsForEmail"
        :loading="loading"
      >
        <template v-slot:[`item.timestamp`]="{ item }">
          {{ item.timestamp | moment }}
        </template>
        <template v-slot:[`item.emails`]="{ item }">
          <v-btn
            v-if="item.emails != ''"
            icon
            @click="oepnMessage(item.emails)"
          >
            <v-icon small>mdi-email</v-icon>
          </v-btn>
        </template>
        <template v-slot:[`item.message`]="{ item }">
          <v-btn icon @click="oepnMessage(item.message)">
            <v-icon small>mdi-note</v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <v-dialog
        v-model="messageDialog"
        max-width="500"
        @click:outside="messageClose"
      >
        <v-card>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <p style="white-space:pre-wrap;" v-text="message"></p>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn depressed @click="messageClose">
              閉じる
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-tab-item>
  </v-tabs>
</template>

<script>
import moment from 'moment'
import {
  NOTIFICATION_STATUS_LIST,
  DEVICE_ALERT_TYPE_LIST_FOR_NOTIFICATION_LOG
} from '@/constants'

export default {
  name: 'DeviceLogTab',
  props: {
    loading: Boolean,
    deviceAlertLogs: Array,
    notificationMessageLogsForLine: Array,
    notificationMessageLogsForEmail: Array
  },
  data() {
    return {
      deviceAlertHeaders: [
        {
          text: 'アラート日時',
          align: 'start',
          value: 'timestamp'
        },
        {
          text: 'LINE送信件数',
          align: 'end',
          sortable: false,
          value: 'send_count'
        },
        {
          text: 'メール送信件数',
          align: 'end',
          sortable: false,
          value: 'send_count_for_email'
        },
        {
          text: '通知タイプ',
          align: 'start',
          value: 'send_type'
        },
        {
          text: '浸水アラートの通知設定',
          align: 'start',
          value: 'device_alert_type'
        }
      ],
      notificationMessageHeadersForLine: [
        {
          text: '送信日時',
          align: 'start',
          value: 'timestamp'
        },
        {
          text: 'ユーザー',
          align: 'start',
          value: 'users'
        },
        {
          text: 'メッセージ',
          sortable: false,
          value: 'message'
        }
      ],
      notificationMessageHeadersForEmail: [
        {
          text: '送信日時',
          align: 'start',
          value: 'timestamp'
        },
        {
          text: 'メールアドレス',
          align: 'start',
          value: 'emails'
        },
        {
          text: 'メッセージ',
          sortable: false,
          value: 'message'
        }
      ],
      messageDialog: false,
      message: ''
    }
  },
  methods: {
    oepnMessage(message) {
      this.message = message
      this.messageDialog = true
    },
    messageClose() {
      this.message = ''
      this.messageDialog = false
    }
  },
  filters: {
    moment(timestamp) {
      return moment(timestamp).format('YYYY/MM/DD HH:mm:ss')
    },
    sendTypeLabel(value) {
      const target = NOTIFICATION_STATUS_LIST.find(i => i.value === value)
      if (target === undefined) {
        return ''
      }
      return target.label
    },
    deviceAlertTypeTypeLabel(value) {
      const target = DEVICE_ALERT_TYPE_LIST_FOR_NOTIFICATION_LOG.find(
        i => i.value === value
      )
      if (target === undefined) {
        return 'LINE通知'
      }
      return target.label
    }
  }
}
</script>
