<template>
  <v-dialog v-model="dialog" max-width="920" @click:outside="close">
    <v-card v-if="loading" min-width="320">
      <v-card-text>
        Please stand by
        <v-progress-linear
          indeterminate
          color="grey"
          class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
    <v-card v-else>
      <v-card-title>
        <span class="headline">センサ名: {{ deviceName }} の設置画像管理</span>
      </v-card-title>
      <v-card-text>
        <div class="pb-4" cols="12" md="6">
          設置画像の登録、編集、削除が行えます。
        </div>
        <v-container class="py-4">
          <v-form ref="form" v-model="valid" lazy-validation @submit.prevent>
            <template v-if="!isShowForm">
              <v-row class="pb-4">
                <h2>登録画像</h2>
              </v-row>
              <template v-if="pictures.length > 0">
                <v-row>
                  <div>{{ pictureNo + 1 }} / {{ pictures.length }} 枚目</div>
                  <v-carousel hide-delimiters v-model="pictureNo" height="50vh">
                    <template v-for="pic of pictures">
                      <v-carousel-item
                        :key="pic.picture_id"
                        :placeholder="pic.description"
                        height="auto"
                      >
                        <v-sheet height="100%" color="white">
                          <img
                            :src="generatePreviewURL(pic.get_url)"
                            width="100%"
                            height="100%"
                            style="object-fit: contain;"
                          />
                        </v-sheet>
                      </v-carousel-item>
                    </template>
                  </v-carousel>
                </v-row>
                <v-row>
                  <v-col>
                    <!-- carousel の中に入れると画像の比率が少しおかしくなるため外に出している -->
                    {{ pictures[pictureNo].description }}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-btn
                      block
                      class="mt-2"
                      @click="showUpdateForm(pictures[pictureNo].picture_id)"
                    >
                      <v-icon small>
                        mdi-upload
                      </v-icon>
                      {{ pictureNo + 1 }}枚目の画像を更新する
                    </v-btn>
                  </v-col>
                  <v-col>
                    <v-btn
                      block
                      class="mt-2"
                      @click="deletePicture(pictures[pictureNo].picture_id)"
                    >
                      <v-icon small>
                        mdi-delete
                      </v-icon>
                      {{ pictureNo + 1 }}枚目の画像を削除する
                    </v-btn>
                  </v-col>
                </v-row>
              </template>
              <template v-else>
                <v-row>
                  登録された画像がありません。
                </v-row>
              </template>

              <v-row>
                <v-col>
                  <v-btn
                    block
                    class="mt-2"
                    @click="showCreateForm()"
                    :color="mainColor"
                    dark
                  >
                    <v-icon small>
                      mdi-plus-box
                    </v-icon>
                    画像を新規に登録する
                  </v-btn>
                </v-col>
              </v-row>
            </template>

            <!-- フォーム -->
            <template v-else>
              <v-row>
                <v-col cols="12">
                  <v-row class="py-4">
                    <h2 id="formHeader">{{ formHeader }}</h2>
                  </v-row>
                  <v-file-input
                    clearable
                    :label="formData.inputLabel"
                    accept="image/*"
                    prepend-icon="mdi-camera"
                    variant="underlined"
                    v-model="selectedPicture"
                    :rules="rules.picture"
                  ></v-file-input>
                  <v-row>
                    <v-col cols="10">
                      <img
                        v-if="formData.previewURL"
                        :src="generatePreviewURL(formData.previewURL)"
                        width="100%"
                        style="max-height: 50vh; object-fit: contain;"
                      />
                    </v-col>
                  </v-row>
                  <v-text-field
                    v-model="formData.description"
                    label="設置日等入力してください"
                  ></v-text-field>
                  <v-row>
                    <v-col>
                      <v-btn block class="mt-2" @click="closeForm">
                        <v-icon small>
                          mdi-undo-variant
                        </v-icon>
                        {{ formData.cancelLabel }}</v-btn
                      >
                    </v-col>
                    <v-col>
                      <v-btn
                        block
                        class="mt-2"
                        dark
                        :color="mainColor"
                        @click="submit"
                      >
                        <v-icon small>
                          mdi-upload
                        </v-icon>
                        {{ formData.submitLabel }}</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </template>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn depressed @click="close">
          閉じる
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import {
  getDevicePictures,
  postDevicePicture,
  putDevicePicture,
  deleteDevicePicture,
  uploadDevicePicureToS3
} from '@/api/index'
import { MAIN_COLOR } from '@/constants'

export default {
  name: 'DevicePictureManagementDialog',
  props: {
    dialog: Boolean,
    deviceId: String,
    deviceName: String
  },
  data() {
    return {
      mainColor: MAIN_COLOR,
      loading: false,
      valid: true,
      pictureNo: 0,
      pictures: [],
      urlCache: {},

      // form
      isShowForm: false,
      isCreate: true,
      disableButton: false,
      selectedPicture: null,
      formHeader: '画像を新規に登録する',
      // フォームの各要素の値。 initForm, showUpdateForm で設定する
      formData: {},
      rules: {
        picture: [v => !this.isCreate || !!v || '画像を選択してください']
      }
    }
  },
  async created() {
    this.loading = true
    await this.initDevicePicture()
    this.loading = false
  },
  methods: {
    ...mapActions(['setFullscreenLoading']),
    async initDevicePicture() {
      console.log('initDevicePicture')
      try {
        const res = await getDevicePictures(this.deviceId)
        this.pictures = res.pictures
      } catch (e) {
        console.error(e)
        alert('サーバエラーが発生しました')
      }
    },
    async submit() {
      if (!this.$refs.form.validate()) {
        return
      }

      this.setFullscreenLoading(true)

      let resPicture = null

      // データ登録
      try {
        if (this.isCreate) {
          const res = await postDevicePicture(
            this.deviceId,
            this.formData.description
          )
          resPicture = res.picture
        } else {
          const res = await putDevicePicture(
            this.deviceId,
            this.formData.pictureId,
            this.formData.description
          )
          resPicture = res.picture
        }
      } catch (e) {
        console.error(e)
        alert('サーバエラーが発生しました')
        this.setFullscreenLoading(false)
        return
      }

      // 編集かつ説明のみの更新の場合、画像のアップロードは行わない
      if (!this.isCreate && !this.selectedPicture) {
        window.alert('説明の更新を行いました')
        this.editPictureLocal(resPicture.picture_id, resPicture)
        this.closeForm()
        this.setFullscreenLoading(false)
        return
      }

      // 画像アップロード
      await uploadDevicePicureToS3(resPicture.upload_url, this.selectedPicture)
        .then(() => {
          if (this.isCreate) {
            this.addPictureLocal(resPicture)
            // カルーセルを末尾に移動させる
            this.pictureNo = this.pictures.length - 1
          } else {
            this.editPictureLocal(resPicture.picture_id, resPicture)
            this.clearURLCache(resPicture.get_url)
          }
          window.alert('画像を登録しました')
          this.closeForm()
        })
        .catch(e => {
          console.error(e)
          alert('画像のアップロードに失敗しました。')
        })

      this.setFullscreenLoading(false)
    },
    async deletePicture(pictureId) {
      if (!window.confirm('画像を削除しますか？')) {
        return
      }

      this.setFullscreenLoading(true)
      try {
        await deleteDevicePicture(this.deviceId, pictureId)
        this.closeForm()
        window.alert('画像を削除しました')
      } catch (e) {
        console.error(e)
        alert('サーバエラーが発生しました')
        this.setFullscreenLoading(false)
        return
      }
      this.setFullscreenLoading(false)
      this.initForm()
      this.deletePictureLocal(pictureId)
    },
    showCreateForm() {
      this.isShowForm = true
      this.disableButton = true

      this.initForm()
    },
    showUpdateForm(pictureId) {
      this.initForm()
      this.isShowForm = true
      this.isCreate = false
      this.formHeader = '画像を更新する'
      this.disableButton = true

      const picture = this.findPictureLocal(pictureId)
      this.formData = {
        pictureId: pictureId,
        inputLabel: '更新する画像を選択してください',
        submitLabel: '画像を更新する',
        cancelLabel: '画像の更新をやめる',
        description: picture.description,
        previewURL: picture.get_url
      }
    },
    closeForm() {
      this.isShowForm = false
      this.disableButton = false
      this.selectedPicture = null
    },
    addPictureLocal(picture) {
      this.pictures.push(picture)
    },
    editPictureLocal(pictureId, picture) {
      const index = this.pictures.findIndex(p => p.picture_id === pictureId)
      if (index === -1) {
        return
      }
      this.pictures.splice(index, 1, picture)
    },
    findPictureLocal(pictureId) {
      const index = this.pictures.findIndex(p => p.picture_id === pictureId)
      if (index === -1) {
        return
      }
      return this.pictures[index]
    },
    deletePictureLocal(pictureId) {
      const index = this.pictures.findIndex(p => p.picture_id === pictureId)
      if (index === -1) {
        return
      }
      if (this.pictureNo === this.pictures.length - 1) {
        this.pictureNo--
      }
      this.pictures.splice(index, 1)
    },
    initForm() {
      this.$refs.form.resetValidation()

      this.isCreate = true
      this.selectedPicture = null
      this.formHeader = '画像を新規に登録する'
      this.formData = {
        pictureId: null,
        inputLabel: '登録する画像を選択してください',
        submitLabel: '画像を登録する',
        cancelLabel: '新規登録をやめる',
        description: '',
        previewURL: null
      }
    },
    generatePreviewURL(url) {
      if (this.urlCache[url]) {
        return this.urlCache[url]
      }

      this.urlCache[url] = this.selectedPicture
        ? url
        : `${url}?v=${this.timestamp()}`
      return this.urlCache[url]
    },
    clearURLCache(url) {
      delete this.urlCache[url]
    },
    timestamp() {
      return new Date().getTime()
    },
    close() {
      this.initForm()

      this.$emit('close')
    }
  },
  watch: {
    selectedPicture() {
      if (!this.selectedPicture) {
        this.formData.previewURL = null
        return
      }

      this.formData.previewURL = URL.createObjectURL(this.selectedPicture)
    },
    pictureNo() {
      this.closeForm()
    }
  }
}
</script>
