<template>
  <v-dialog
    v-model="dialog"
    max-width="800"
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card>
      <v-card-title>
        <span class="headline">通知設定ユーザー</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-data-table
                class="elevation-0"
                :headers="headers"
                :items="userDevices"
                :loading="loading"
              >
                <template v-slot:[`item.user_picture`]="{ item }">
                  <v-avatar height="32px" width="32px" min-width="32px">
                    <img
                      v-if="item.user_picture !== ''"
                      :src="item.user_picture"
                      :alt="item.name"
                    />
                    <v-icon v-else>mdi-account-circle</v-icon>
                  </v-avatar>
                </template>
                <template v-slot:[`item.user_group_name`]="{ item }">
                  {{ item.user_group_name }}
                  <span v-if="isAdminRole(item.user_role)">(メンバー)</span>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn depressed @click="close">
          閉じる
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import { getDevice } from '@/api'
import { MAIN_COLOR } from '@/constants'

export default {
  name: 'UserDeviceListByDevice',
  props: {
    dialog: Boolean,
    deviceId: String
  },
  data() {
    return {
      mainColor: MAIN_COLOR,
      loading: false,
      defaultHeaders: [
        { text: 'ID', sortable: false, value: 'user_id' },
        { text: 'ユーザー名', value: 'user_name', sortable: true },
        { text: 'アバター', value: 'user_picture', sortable: false },
        { text: 'グループ', value: 'user_group_name', sortable: true }
      ],
      userDevices: []
    }
  },
  computed: {
    ...mapGetters(['deviceById', 'isAdminGroup']),
    headers() {
      if (this.isAdminGroup) {
        return this.defaultHeaders
      }
      return this.defaultHeaders.filter(h => h.value !== 'user_group_name')
    }
  },
  async created() {
    this.loading = true
    await this.initUserDevices()
    this.loading = false
  },
  methods: {
    async initUserDevices() {
      let res
      try {
        res = await getDevice(this.deviceId)
      } catch (e) {
        console.error(e)
        alert('サーバーエラーが発生しました。')
        return
      }
      this.userDevices = res.user_devices
    },
    close() {
      this.$emit('close')
    },
    isAdminRole(role) {
      return role === 'admin'
    }
  }
}
</script>
