<template>
  <v-dialog
    v-model="dialog"
    max-width="920"
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ title }}</span>
      </v-card-title>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card-text>
          <v-container>
            <v-row v-show="!isCreate">
              <v-col cols="12">
                <v-text-field
                  v-model="item.id"
                  label="ID"
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="item.name"
                  label="センサ設置場所の名前"
                  hint="※初期設定のアラートメッセージにも使用します。"
                  :persistent-hint="true"
                  :rules="rules.name"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-select
                  v-model="item.group"
                  label="グループ"
                  :items="groupNames"
                  item-text="name"
                  item-value="id"
                  :rules="rules.group"
                  required
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="item.series_name"
                  label="シリーズ名"
                  :persistent-hint="true"
                  :rules="rules.series_name"
                  :readonly="!isAdminGroup"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="item.imsi"
                  label="IMSI (SIMの識別番号)"
                  :rules="rules.number"
                  :readonly="!isAdminGroup"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="item.imei"
                  label="IMEI (通信端末の識別番号)"
                  :rules="rules.number"
                  :readonly="!isAdminGroup"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pb-0">
                <h3 class="subtitle-1">設置位置</h3>
                <p class="caption text--secondary">
                  ※Googleマップで設置場所の緯度経度を調べて入力してください。
                </p>
              </v-col>
              <v-col cols="6" class="pt-0">
                <v-text-field
                  v-model="item.position.lat"
                  label="緯度"
                  :rules="rules.number"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="pt-0">
                <v-text-field
                  v-model="item.position.lng"
                  label="経度"
                  :rules="rules.number"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="item.agent"
                  label="購入代理店"
                  :readonly="!isAdminGroup"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="item.product_serial_number"
                  label="製品シリアル"
                  :readonly="!isAdminGroup"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="isAdminGroup">
              <v-col cols="12">
                <v-text-field
                  v-model="item.circuit_board_serial_number"
                  label="ロットナンバー"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="d-flex align-center">
              <v-col cols="12" class="pb-0">
                <h3 class="subtitle-1">ステータス</h3>
                <p class="caption text--secondary">
                  ※無効にするとマップ表示、通知も無効になります。<br />
                  ※基本的に常時有効にしてください。 (青色点灯)
                </p>
                <v-switch
                  v-model="item.device_status"
                  label="無効 / 有効"
                  :persistent-hint="true"
                  :false-value="deviceStatusDisabledValue"
                  :true-value="deviceStatusEnabledValue"
                >
                </v-switch>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" class="pb-0">
                <span class="subtitle-1">マップ表示</span>
              </v-col>
              <v-col cols="6" class="pb-0">
                <span class="subtitle-1">通知</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" class="pt-0">
                <v-radio-group v-model="item.map_display_status" column>
                  <v-radio
                    v-for="i in mapDisplayStatusItems"
                    :key="i.value"
                    :label="i.label"
                    :value="i.value"
                    :disabled="deviceStatusDisabled"
                  ></v-radio>
                </v-radio-group>
                <p class="caption text--secondary">
                  ※「グループに対してのみ表示」はクローズで<br />
                  使用したい場合に使用します。
                </p>
              </v-col>
              <v-col cols="6" class="pt-0">
                <v-radio-group v-model="item.notification_status" column>
                  <v-radio
                    v-for="i in notificationStatusItems"
                    :key="i.value"
                    :label="i.label"
                    :value="i.value"
                    :disabled="deviceStatusDisabled"
                  ></v-radio>
                </v-radio-group>
                <p class="caption text--secondary">
                  ※「メンバーにのみテスト送信」は<br />
                  定期点検をする際などに使用します。
                </p>
              </v-col>
            </v-row>
            <v-row v-show="!isCreate">
              <v-col>
                <v-text-field
                  v-model="item.latest_alerted_at"
                  label="最終アラート日時"
                  readonly
                >
                  <template v-if="item.is_alert" v-slot:append-outer>
                    <v-btn style="top: -7px" top depressed @click="alertClear"
                      >アラート解除</v-btn
                    >
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row v-show="!isCreate && isAdminGroup">
              <v-col>
                <h3 class="subtitle-1">自己診断状態</h3>
                <p class="caption text--secondary">
                  ※異常（赤色点灯）になると管理画面のマップ上で自己診断異常表示になります。
                </p>
                <v-switch
                  v-model="item.is_device_alive_alert"
                  label="正常 / 異常"
                  color="red"
                  :persistent-hint="true"
                >
                </v-switch>
              </v-col>
            </v-row>
            <v-row v-show="isAdminGroup">
              <v-col>
                <h3 class="subtitle-1">長期化バッテリー設定</h3>
                <p class="caption text--secondary">
                  有効にすると、バッテリー残量が0.25以下になった後に一定回数の通信が来るまで、バッテリーが赤色表示になりません。<br />
                  回数の設定は、設定画面の「threshold_for_red_battery」から行うことができます。
                </p>
                <v-switch
                  v-model="item.is_long_battery_device"
                  label="無効 / 有効"
                  :persistent-hint="true"
                >
                </v-switch>
                <p class="caption text--secondary">
                  バッテリー残量が0.25以下になってからのカウント数:
                  {{ lowBatteyCount }}
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea
                  v-model="item.description"
                  label="備考"
                  counter="2048"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row v-show="!isCreate">
              <v-col cols="12" md="6" class="pt-0">
                <v-text-field
                  v-model="item.creator"
                  label="登録者"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="pt-0">
                <v-text-field
                  v-model="item.created_at"
                  label="登録日時"
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-show="!isCreate">
              <v-col cols="12" md="6" class="pt-0">
                <v-text-field v-model="item.updator" label="更新者" readonly>
                </v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="pt-0">
                <v-text-field
                  v-model="item.updated_at"
                  label="更新日時"
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed @click="close">
            キャンセル
          </v-btn>
          <v-btn depressed dark :color="mainColor" @click="save">保存</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { postDevice, putDevice } from '@/api'
import {
  DEVICE_STATUS_ENABLED,
  DEVICE_STATUS_DISABLED,
  MAP_DISPLAY_STATUS_LIST,
  NOTIFICATION_STATUS_LIST,
  MAP_DISPLAY_STATUS_ALL,
  NOTIFICATION_STATUS_ALL,
  MAIN_COLOR,
  IS_CLOSED,
  TIME_IS_ZERO
} from '@/constants'

const alertClearMessage = `センサの浸水アラート状態を解除するために
最終アラート日時をクリアします。
確定するにはクリア後に保存してください。
クリアしてよろしいですか？
`

export default {
  name: 'DeviceEditor',
  props: {
    dialog: Boolean,
    deviceId: String
  },
  data() {
    return {
      isClosed: IS_CLOSED,
      mainColor: MAIN_COLOR,
      valid: true,
      deviceStatusDisabledValue: DEVICE_STATUS_DISABLED,
      deviceStatusEnabledValue: DEVICE_STATUS_ENABLED,
      rules: {
        name: [
          v => !!v || 'センサ設置場所の名前は必須です',
          v => {
            if (v === this.oldDeviceName) {
              return true
            }
            return v in this.deviceNameMap
              ? `${v} は存在するセンサ設置場所の名前のため使用できません。`
              : true
          }
        ],
        group: [v => !!v || 'グループは必須です'],
        number: [v => v === '' || !isNaN(v) || '数値を入力してください'],
        series_name: [
          v =>
            !this.isAdminGroup ||
            !this.isCreate ||
            !!v ||
            'シリーズ名は必須です'
        ]
      },
      defaultItem: {
        id: '',
        name: '',
        group: '',
        series_name: '',
        imsi: null,
        imei: null,
        position: {
          lat: null,
          lng: null
        },
        agent: '',
        product_serial_number: '',
        circuit_board_serial_number: '',
        device_status: 'disabled',
        map_display_status: 'hide',
        notification_status: 'none',
        is_alert: false,
        latest_alerted_at: null,
        is_device_alive_alert: false,
        is_long_battery_device: false,
        description: ''
      },
      editItem: {
        id: '',
        name: '',
        group: '',
        series_name: '',
        imsi: null,
        imei: null,
        position: {
          lat: null,
          lng: null
        },
        agent: '',
        product_serial_number: '',
        circuit_board_serial_number: '',
        device_status: 'disabled',
        map_display_status: 'hide',
        notification_status: 'none',
        is_alert: false,
        latest_alerted_at: null,
        is_device_alive_alert: false,
        is_long_battery_device: false,
        description: ''
      },
      oldDeviceName: '',
      lowBatteyCount: 0
    }
  },
  computed: {
    ...mapGetters([
      'deviceById',
      'groupNames',
      'settings',
      'isAdminGroup',
      'deviceNameMap'
    ]),
    isCreate() {
      return this.deviceId === ''
    },
    title() {
      return this.isCreate ? '新規登録' : '編集'
    },
    item() {
      return this.editItem
    },
    deviceStatusDisabled() {
      return this.item.device_status === DEVICE_STATUS_DISABLED
    },
    mapDisplayStatusItems() {
      if (IS_CLOSED) {
        return MAP_DISPLAY_STATUS_LIST.filter(
          status => status.value !== MAP_DISPLAY_STATUS_ALL
        )
      }
      return MAP_DISPLAY_STATUS_LIST
    },
    notificationStatusItems() {
      if (IS_CLOSED) {
        return NOTIFICATION_STATUS_LIST.filter(
          status => status.value !== NOTIFICATION_STATUS_ALL
        )
      }
      return NOTIFICATION_STATUS_LIST
    }
  },
  watch: {
    async dialog(openend) {
      if (openend && !this.isCreate) {
        await this.setItems()
      }
      // モーダル開閉のタイミングでバリデーションをリセットしないと、開き直した後のバリデーションがおかしくなる
      this.$refs.form.resetValidation()
    }
  },
  methods: {
    ...mapActions(['addDevice', 'updateDevice', 'setFullscreenLoading']),
    close() {
      this.$refs.form.resetValidation()
      this.editItem = Object.assign({}, this.defaultItem)
      this.editItem.position = Object.assign({}, this.defaultItem.position)
      this.oldDeviceName = ''
      this.$emit('close')
    },
    async save() {
      if (!this.$refs.form.validate()) {
        return
      }
      this.setFullscreenLoading(true)
      try {
        if (this.isCreate) {
          const res = await postDevice(this.item)
          this.addDevice(res.device)
        } else {
          const res = await putDevice(this.item, 'base')
          this.updateDevice(res.device)
        }
      } catch (e) {
        console.error(e)
        alert('サーバエラーが発生しました')
      }
      this.close()
      this.setFullscreenLoading(false)
    },
    alertClear() {
      if (confirm(alertClearMessage)) {
        this.item.latest_alerted_at = null
      }
    },
    async setItems() {
      const device = this.deviceById(this.deviceId)
      this.oldDeviceName = device.name
      if (device.latest_alerted_at === TIME_IS_ZERO) {
        device.latest_alerted_at = null
      }
      this.lowBatteyCount = device.low_battery_count
      return Object.assign(this.editItem, device)
    }
  }
}
</script>
