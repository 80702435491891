var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-main',[_c('v-container',[_c('v-row',[_c('v-col',[_c('h1',[_vm._v("センサ")])]),(_vm.isAdminGroup)?_c('v-spacer'):_vm._e(),(_vm.isAdminGroup)?_c('v-col',{staticClass:"text-right"},[_c('v-btn',{staticClass:"mb-2",attrs:{"color":_vm.mainColor,"dark":""},on:{"click":function($event){$event.stopPropagation();return _vm.doCreateDevice.apply(null, arguments)}}},[_vm._v("新規登録")])],1):_vm._e()],1),_c('v-card',[_c('v-card-title',[_c('v-text-field',{attrs:{"value":_vm.searchDevices,"append-icon":"mdi-magnify","label":"検索","single-line":"","hide-details":""},on:{"input":_vm.setSearchDevices}}),(_vm.isAdminGroup)?_c('v-spacer'):_vm._e(),(_vm.isAdminGroup)?_c('v-select',{staticStyle:{"top":"10px"},attrs:{"value":_vm.searchDevicesByGroup,"label":"グループで絞り込み","items":_vm.groupNames,"item-text":"name","item-value":"id","clearable":""},on:{"input":_vm.setSearchDevicesByGroup}}):_vm._e(),_c('v-spacer'),_c('v-btn',{staticClass:"mr-2",attrs:{"plain":""},on:{"click":function($event){$event.stopPropagation();return _vm.downloadCsv.apply(null, arguments)}}},[_vm._v(" センサ一覧の"),_c('br'),_vm._v("CSVダウンロード "),_c('v-icon',{staticClass:"ml-2",attrs:{"small":""}},[_vm._v(" mdi-download ")])],1),_c('v-divider',{attrs:{"vertical":""}}),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.doLoad(false)}}},[_c('v-progress-circular',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],attrs:{"indeterminate":_vm.loading}}),_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}]},[_vm._v("mdi-reload")])],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"search":_vm.searchDevices},scopedSlots:_vm._u([{key:"item.group",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.displayGroupName(item.group))+" ")]}},{key:"item.device_status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.device_status === 'enabled' ? 'success' : 'grey',"label":"","small":"","outlined":""}},[_vm._v(_vm._s(item.device_status === 'enabled' ? '有効' : '無効')+" ")])]}},{key:"item.map_display_status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.mapDisplayStatusLabel(item.map_display_status))+" ")]}},{key:"item.notification_status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.notificationStatusLabel(item.notification_status))+" ")]}},{key:"item.is_alert",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"color":item.is_alert ? 'error' : 'success',"small":""}},[_vm._v(_vm._s(item.is_alert ? 'mdi-alert' : 'mdi-check-circle'))])]}},{key:"item.log",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.doOpenLog(item.id, item.name)}}},[_vm._v("mdi-card-text")])]}},{key:"item.battery_level",fn:function(ref){
var item = ref.item;
return [(item.battery_level !== 0)?_c('v-img',{attrs:{"max-height":"24","width":"24","src":_vm.displayBatteryLevel(
                item.battery_level,
                item.is_long_battery_device,
                item.low_battery_count
              ),"contain":""}}):_vm._e()]}},{key:"item.series_name",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.displaySeriesName(item))+" ")]}},{key:"item.is_device_alive_alert",fn:function(ref){
              var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"color":item.is_device_alive_alert ? 'error' : 'success',"small":""}},[_vm._v(_vm._s(item.is_device_alive_alert ? 'mdi-close-circle' : 'mdi-check-circle'))])]}},{key:"item.latest_received_at",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.latest_received_at))+" ")]}},{key:"item.created_at",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.created_at))+" ")]}},{key:"item.user_devices",fn:function(ref){
              var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.doOpenUserDevices(item.id)}}},[_vm._v("mdi-bell")])]}},{key:"item.picture",fn:function(ref){
              var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.doPictureManagement(item.id, item.name)}}},[_vm._v(" mdi-camera ")])]}},{key:"item.setting",fn:function(ref){
              var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.doSettingEdit(item.id)}}},[_vm._v(" mdi-cog ")])]}},{key:"item.action",fn:function(ref){
              var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.doEditDevice(item.id)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.doDeleteDevice(item)}}},[_vm._v("mdi-delete")])]}},{key:"no-data",fn:function(){return [_vm._v("現在登録されたセンサはありません。")]},proxy:true}],null,true)})],1),(_vm.openUserDevicesDeviceId !== '')?_c('user-device-list-by-device',{attrs:{"dialog":_vm.userDevicesDialog,"deviceId":_vm.openUserDevicesDeviceId},on:{"close":_vm.close}}):_vm._e(),(_vm.openLogDeviceId !== '' && _vm.openLogDeviceName !== '')?_c('device-log-dialog',{attrs:{"dialog":_vm.logDialog,"deviceId":_vm.openLogDeviceId,"deviceName":_vm.openLogDeviceName},on:{"close":_vm.close}}):_vm._e(),_c('device-editor',{attrs:{"dialog":_vm.deviceDialog,"deviceId":_vm.editDeviceId},on:{"close":_vm.close}}),_c('device-setting-editor',{attrs:{"dialog":_vm.settingDialog,"id":_vm.settingEditDeviceId},on:{"close":_vm.close}}),(
        _vm.managementPictureDeviceId !== '' && _vm.managementPictureDeviceName !== ''
      )?_c('device-picture-management-dialog',{attrs:{"dialog":_vm.managementPictureDialog,"deviceId":_vm.managementPictureDeviceId,"deviceName":_vm.managementPictureDeviceName},on:{"close":_vm.close}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }