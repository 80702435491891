<template>
  <v-dialog v-model="dialog" max-width="920" @click:outside="close">
    <v-card>
      <v-card-title>
        <span class="headline">センサ名: {{ deviceName }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <device-log-tab
                :loading="loading"
                :deviceAlertLogs="deviceAlertLogs"
                :notificationMessageLogsForLine="notificationMessageLogsForLine"
                :notificationMessageLogsForEmail="
                  notificationMessageLogsForEmail
                "
              ></device-log-tab>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn depressed @click="close">
          閉じる
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { getDeviceAlertLog, getNotificationMessageLog } from '@/api'
import DeviceLogTab from '@/components/device-log-tab'

export default {
  name: 'DeviceLogDialog',
  components: {
    DeviceLogTab
  },
  props: {
    dialog: Boolean,
    deviceId: {
      type: String,
      required: true
    },
    deviceName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      deviceAlertLogs: [],
      notificationMessageLogs: []
    }
  },
  computed: {
    notificationMessageLogsForLine() {
      if (this.notificationMessageLogs.length === 0) {
        return []
      }
      return this.notificationMessageLogs.filter(
        log => log.device_alert_type === 'line'
      )
    },
    notificationMessageLogsForEmail() {
      if (this.notificationMessageLogs.length === 0) {
        return []
      }
      return this.notificationMessageLogs.filter(
        log => log.device_alert_type === 'mail'
      )
    }
  },
  async created() {
    this.loading = true
    await this.initDeviceAlertLog()
    await this.initNotificationMessageLog()
    this.loading = false
  },
  methods: {
    async initDeviceAlertLog() {
      if (
        this.deviceAlertLogs.length > 0 &&
        this.deviceAlertLogs[0].device_id === this.deviceId
      ) {
        return
      }
      let res
      try {
        res = await getDeviceAlertLog(this.deviceId)
      } catch (e) {
        console.error(e)
        alert('サーバエラーが発生しました')
        return
      }
      this.deviceAlertLogs = res.device_alert_logs
    },
    async initNotificationMessageLog() {
      if (
        this.notificationMessageLogs.length > 0 &&
        this.notificationMessageLogs[0].device_id === this.deviceId
      ) {
        return
      }
      let res
      try {
        res = await getNotificationMessageLog(this.deviceId)
      } catch (e) {
        console.error(e)
        alert('サーバエラーが発生しました')
        return
      }
      this.notificationMessageLogs = res.notification_message_logs
    },
    close() {
      this.$emit('close')
    }
  }
}
</script>
