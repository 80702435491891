<template>
  <v-main>
    <v-container>
      <v-row>
        <v-col>
          <h1>センサ</h1>
        </v-col>
        <v-spacer v-if="isAdminGroup"></v-spacer>
        <v-col v-if="isAdminGroup" class="text-right">
          <v-btn
            class="mb-2"
            :color="mainColor"
            dark
            @click.stop="doCreateDevice"
            >新規登録</v-btn
          >
        </v-col>
      </v-row>
      <v-card>
        <v-card-title>
          <v-text-field
            :value="searchDevices"
            append-icon="mdi-magnify"
            label="検索"
            single-line
            hide-details
            @input="setSearchDevices"
          ></v-text-field>
          <v-spacer v-if="isAdminGroup"></v-spacer>
          <v-select
            v-if="isAdminGroup"
            :value="searchDevicesByGroup"
            label="グループで絞り込み"
            :items="groupNames"
            item-text="name"
            item-value="id"
            style="top: 10px"
            clearable
            @input="setSearchDevicesByGroup"
          ></v-select>
          <v-spacer></v-spacer>
          <v-btn @click.stop="downloadCsv" class="mr-2" plain>
            センサ一覧の<br />CSVダウンロード
            <v-icon small class="ml-2">
              mdi-download
            </v-icon>
          </v-btn>
          <v-divider vertical></v-divider>
          <v-btn icon @click="doLoad(false)">
            <v-progress-circular
              v-show="loading"
              :indeterminate="loading"
            ></v-progress-circular>
            <v-icon v-show="!loading">mdi-reload</v-icon>
          </v-btn>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          :search="searchDevices"
        >
          <template v-slot:[`item.group`]="{ item }">
            {{ displayGroupName(item.group) }}
          </template>
          <template v-slot:[`item.device_status`]="{ item }">
            <v-chip
              :color="item.device_status === 'enabled' ? 'success' : 'grey'"
              label
              small
              outlined
              >{{ item.device_status === 'enabled' ? '有効' : '無効' }}
            </v-chip>
          </template>
          <template v-slot:[`item.map_display_status`]="{ item }">
            {{ mapDisplayStatusLabel(item.map_display_status) }}
          </template>
          <template v-slot:[`item.notification_status`]="{ item }">
            {{ notificationStatusLabel(item.notification_status) }}
          </template>
          <template v-slot:[`item.is_alert`]="{ item }">
            <v-icon
              class="mr-2"
              :color="item.is_alert ? 'error' : 'success'"
              small
              >{{ item.is_alert ? 'mdi-alert' : 'mdi-check-circle' }}</v-icon
            >
          </template>
          <template v-slot:[`item.log`]="{ item }">
            <v-icon small @click.stop="doOpenLog(item.id, item.name)"
              >mdi-card-text</v-icon
            >
          </template>
          <template v-slot:[`item.battery_level`]="{ item }">
            <v-img
              v-if="item.battery_level !== 0"
              max-height="24"
              width="24"
              :src="
                displayBatteryLevel(
                  item.battery_level,
                  item.is_long_battery_device,
                  item.low_battery_count
                )
              "
              contain
            ></v-img>
          </template>
          <template v-slot:[`item.series_name`]="{ item }">
            {{ displaySeriesName(item) }}
          </template>
          <template v-slot:[`item.is_device_alive_alert`]="{ item }">
            <v-icon
              class="mr-2"
              :color="item.is_device_alive_alert ? 'error' : 'success'"
              small
              >{{
                item.is_device_alive_alert
                  ? 'mdi-close-circle'
                  : 'mdi-check-circle'
              }}</v-icon
            >
          </template>
          <template v-slot:[`item.latest_received_at`]="{ item }">
            {{ item.latest_received_at | moment }}
          </template>
          <template v-slot:[`item.created_at`]="{ item }">
            {{ item.created_at | moment }}
          </template>
          <template v-slot:[`item.user_devices`]="{ item }">
            <v-icon small class="mr-2" @click.stop="doOpenUserDevices(item.id)"
              >mdi-bell</v-icon
            >
          </template>
          <template v-slot:[`item.picture`]="{ item }">
            <v-icon small @click="doPictureManagement(item.id, item.name)">
              mdi-camera
            </v-icon>
          </template>
          <template v-slot:[`item.setting`]="{ item }">
            <v-icon small @click="doSettingEdit(item.id)">
              mdi-cog
            </v-icon>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-icon small class="mr-2" @click.stop="doEditDevice(item.id)"
              >mdi-pencil</v-icon
            >
            <v-icon small @click="doDeleteDevice(item)">mdi-delete</v-icon>
          </template>
          <template v-slot:no-data>現在登録されたセンサはありません。</template>
        </v-data-table>
      </v-card>
      <user-device-list-by-device
        v-if="openUserDevicesDeviceId !== ''"
        :dialog="userDevicesDialog"
        :deviceId="openUserDevicesDeviceId"
        @close="close"
      ></user-device-list-by-device>
      <device-log-dialog
        v-if="openLogDeviceId !== '' && openLogDeviceName !== ''"
        :dialog="logDialog"
        :deviceId="openLogDeviceId"
        :deviceName="openLogDeviceName"
        @close="close"
      ></device-log-dialog>
      <device-editor
        :dialog="deviceDialog"
        :deviceId="editDeviceId"
        @close="close"
      ></device-editor>
      <device-setting-editor
        :dialog="settingDialog"
        :id="settingEditDeviceId"
        @close="close"
      ></device-setting-editor>
      <device-picture-management-dialog
        v-if="
          managementPictureDeviceId !== '' && managementPictureDeviceName !== ''
        "
        :dialog="managementPictureDialog"
        :deviceId="managementPictureDeviceId"
        :deviceName="managementPictureDeviceName"
        @close="close"
      ></device-picture-management-dialog>
    </v-container>
  </v-main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import {
  getDevices,
  deleteDevice,
  getGroups,
  getSettings,
  getDevicesCsvData
} from '@/api'
import { downloadCSVByResponse } from '@/middleware/api'
import DeviceEditor from '@/components/device-editor'
import DeviceLogDialog from '@/components/device-log-dialog'
import DeviceSettingEditor from '@/components/device-setting-editor'
import DevicePictureManagementDialog from '@/components/device-picture-management-dialog'
import UserDeviceListByDevice from '@/components/user-device-list-by-device'
import {
  MAIN_COLOR,
  MAP_DISPLAY_STATUS_LIST,
  NOTIFICATION_STATUS_LIST
} from '@/constants'
import iconBatteryFull from '@/assets/battery_full.svg'
import iconBatteryHigh from '@/assets/battery_high.svg'
import iconBatteryMiddle from '@/assets/battery_middle.svg'
import iconBatteryLow from '@/assets/battery_low.svg'

const onlyAdminHeaderValues = ['group', 'device_alert_log_count']

export default {
  name: 'DeviceIndex',
  components: {
    DeviceEditor,
    DeviceLogDialog,
    DeviceSettingEditor,
    UserDeviceListByDevice,
    DevicePictureManagementDialog
  },
  data: () => ({
    mainColor: MAIN_COLOR,
    loading: false,
    allHeaders: [
      {
        text: 'センサ名',
        align: 'start',
        value: 'name',
        sortable: true
      },
      {
        text: 'グループ',
        align: 'start',
        value: 'group',
        filterable: false,
        sortable: true
      },
      {
        text: '有効 / 無効',
        align: 'start',
        value: 'device_status',
        filterable: false,
        sortable: true
      },
      {
        text: '浸水検知',
        align: 'center',
        value: 'is_alert',
        filterable: false,
        sortable: true
      },
      {
        text: '電池残量',
        align: 'center',
        value: 'battery_level',
        filterable: false,
        sortable: true
      },
      {
        text: 'シリーズ名',
        align: 'center',
        value: 'series_name',
        filterable: false,
        sortable: true
      },
      {
        text: '自己診断状態',
        align: 'center',
        value: 'is_device_alive_alert',
        filterable: false,
        sortable: true
      },
      {
        text: '最終検知日時',
        align: 'center',
        value: 'latest_received_at',
        filterable: false,
        sortable: true
      },
      {
        text: '製品シリアル',
        align: 'start',
        value: 'product_serial_number',
        filterable: false,
        sortable: true
      },
      {
        text: '通知設定ユーザー',
        align: 'center',
        value: 'user_devices',
        filterable: false,
        sortable: false
      },
      {
        text: 'ログ',
        align: 'center',
        value: 'log',
        filterable: false,
        sortable: false
      },
      {
        text: 'ログ累積数',
        align: 'end',
        value: 'device_alert_log_count',
        filterable: false,
        sortable: true
      },
      {
        text: '画像管理',
        value: 'picture',
        align: 'center',
        filterable: false,
        sortable: false
      },
      {
        text: '設定',
        value: 'setting',
        align: 'center',
        filterable: false,
        sortable: false
      },
      {
        text: '編集 / 削除',
        value: 'action',
        align: 'center',
        filterable: false,
        sortable: false
      }
    ],
    deviceDialog: false,
    editDeviceId: '',
    settingDialog: false,
    settingEditDeviceId: '',
    managementPictureDialog: false,
    managementPictureDeviceId: '',
    managementPictureDeviceName: '',
    logDialog: false,
    openLogDeviceId: '',
    openLogDeviceName: '',
    userDevicesDialog: false,
    openUserDevicesDeviceId: '',
    mapDisplayStatusList: MAP_DISPLAY_STATUS_LIST,
    notificationStatusList: NOTIFICATION_STATUS_LIST,
    iconBatteryFull: iconBatteryFull,
    iconBatteryHigh: iconBatteryHigh,
    iconBatteryMiddle: iconBatteryMiddle,
    iconBatteryLow: iconBatteryLow
  }),
  computed: {
    ...mapGetters([
      'devices',
      'groupNames',
      'isAdminGroup',
      'searchDevices',
      'searchDevicesByGroup',
      'settings'
    ]),
    items() {
      if (!this.searchDevicesByGroup) {
        return this.devices
      }
      return this.devices.filter(d => d.group === this.searchDevicesByGroup)
    },
    headers() {
      if (this.isAdminGroup) {
        return this.allHeaders
      }
      return this.allHeaders.filter(
        h => !onlyAdminHeaderValues.includes(h.value)
      )
    }
  },
  async created() {
    this.doLoad(true)
  },
  methods: {
    ...mapActions([
      'setDevices',
      'setGroups',
      'deleteDevice',
      'setSettings',
      'setFullscreenLoading',
      'setSearchDevices',
      'setSearchDevicesByGroup'
    ]),
    async doLoad(storeCheck) {
      if (this.loading) {
        return
      }
      this.loading = true
      await this.initDevice(storeCheck)
      await this.initGroup(storeCheck)
      await this.initSetting(storeCheck)
      this.loading = false
    },
    async initDevice(storeCheck) {
      if (storeCheck && this.$store.getters.devices.length > 0) {
        return
      }
      let res
      try {
        res = await getDevices()
      } catch (e) {
        console.error(e)
        alert('サーバエラーが発生しました')
        return
      }
      this.setDevices(res.devices)
    },
    async initGroup(storeCheck) {
      if (storeCheck && this.$store.getters.groups.length > 0) {
        return
      }
      let res
      try {
        res = await getGroups()
      } catch (e) {
        console.error(e)
        alert('サーバエラーが発生しました')
        return
      }
      this.setGroups(res.groups)
    },
    async initSetting(storeCheck) {
      if (storeCheck && Object.keys(this.$store.getters.settings).length > 0) {
        return
      }
      let res
      try {
        res = await getSettings()
      } catch (e) {
        console.error(e)
        alert('サーバエラーが発生しました')
        return
      }
      this.setSettings(res.settings)
    },
    doCreateDevice() {
      this.editDeviceId = ''
      this.deviceDialog = true
    },
    doEditDevice(deviceId) {
      this.editDeviceId = deviceId
      this.deviceDialog = true
    },
    doSettingEdit(deviceId) {
      this.settingEditDeviceId = deviceId
      this.settingDialog = true
    },
    doPictureManagement(deviceId, deviceName) {
      this.managementPictureDeviceId = deviceId
      this.managementPictureDeviceName = deviceName
      this.managementPictureDialog = true
    },
    async doDeleteDevice(device) {
      if (!confirm(`センサ名: ${device.name} を削除してもよろしいですか？`)) {
        return
      }

      this.setFullscreenLoading(true)
      try {
        await deleteDevice(device.id)
      } catch (e) {
        alert('サーバエラーが発生しました')
        return
      }
      this.deleteDevice(device)
      this.setFullscreenLoading(false)
    },
    doOpenUserDevices(deviceId) {
      this.openUserDevicesDeviceId = deviceId
      this.userDevicesDialog = true
    },
    doOpenLog(deviceId, deviceName) {
      this.openLogDeviceId = deviceId
      this.openLogDeviceName = deviceName
      this.logDialog = true
    },
    close() {
      this.editDeviceId = ''
      this.deviceDialog = false
      this.settingEditDeviceId = ''
      this.settingDialog = false
      this.managementPictureDeviceId = ''
      this.managementPictureDeviceName = ''
      this.managementPictureDialog = false
      this.openUserDevicesDeviceId = ''
      this.userDevicesDialog = false
      this.openLogDeviceId = ''
      this.logDialog = false
    },
    mapDisplayStatusLabel(value) {
      const found = this.mapDisplayStatusList.find(s => s.value === value)
      return found !== undefined ? found.label : ''
    },
    notificationStatusLabel(value) {
      const found = this.notificationStatusList.find(s => s.value === value)
      return found !== undefined ? found.label : ''
    },
    displayGroupName(id) {
      const group = this.groupNames.find(g => g.id == id)
      if (!group) {
        return ''
      }
      return group.name
    },
    displayBatteryLevel(
      batteryLevel,
      isLongBatteryDevice = false,
      lowBatteryCount = 0
    ) {
      switch (batteryLevel) {
        case 1.0:
          return this.iconBatteryFull
        case 0.75:
          return this.iconBatteryHigh
        case 0.5:
          return this.iconBatteryMiddle
        case 0.25:
          /**
           * 長期化バッテリー設定が有効なデバイス用の処理
           *
           * バッテリー残量が0.25以下になってからのカウントが、赤色バッテリーまでの閾値未満の場合、黄色(Middle)バッテリーを表示する
           */
          const thretholdForRedBattery =
            this.settings.threshold_for_red_battery ?? 0
          if (isLongBatteryDevice && lowBatteryCount < thretholdForRedBattery) {
            return this.iconBatteryMiddle
          }

          return this.iconBatteryLow
      }
    },
    displaySeriesName(item) {
      return item.series_name !== '' ? item.series_name : '-'
    },
    async downloadCsv() {
      this.setFullscreenLoading(true)
      let res = null
      try {
        res = await getDevicesCsvData()
      } catch (e) {
        console.error(e)
        alert('サーバエラーが発生しました')
      } finally {
        this.setFullscreenLoading(false)
      }
      if (res === null) {
        return
      }
      // csvダウンロード処理
      downloadCSVByResponse(res)
    }
  },
  filters: {
    moment(timestamp) {
      // timestamp の初期値は以下で登録される。
      if (timestamp === '1970-01-01T09:00:00+09:00') {
        return '-'
      }
      return moment(timestamp).format('YYYY/MM/DD HH:mm:ss')
    }
  }
}
</script>
