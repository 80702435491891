<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="920"
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card v-if="loading" min-width="320">
      <v-card-text>
        Please stand by
        <v-progress-linear
          indeterminate
          color="grey"
          class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
    <v-card v-else>
      <v-card-title>
        <span class="headline">センサ設定</span>
      </v-card-title>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="item.id"
                  label="ID"
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="item.name"
                  label="センサ名"
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pb-0">
                <h4 class="subtitle-1">＜通知メッセージテンプレート＞</h4>
                <p class="caption text--secondary">
                  ※こちらに入力した文章が、アラート通知メッセージとしてユーザーに送信されます。<br />
                  ※入力なしの場合、初期設定メッセージが使用されます。（メッセージ入力欄内をクリックすると初期設定メッセージが表示されます。）<br />
                  ※以下プレイスホルダ（ {timestamp}
                  など）を含めると送信時に置換されます。
                </p>
                <p class="body-2">
                  {timestamp} と入力したところにアラート日時が表示されます。<br />
                  {name} と入力したところにセンサ名が表示されます。<br />
                  {link}
                  と入力したところに浸水アラートマップへのリンクが表示されます。
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea
                  v-model="item.notification_message_template"
                  label="アラート通知メッセージ"
                  counter="2000"
                  :placeholder="settings.notification_message_template"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea
                  v-model="item.test_notification_message_template"
                  label="テストアラート通知メッセージ"
                  counter="2000"
                  :placeholder="settings.test_notification_message_template"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <h4 class="subtitle-1">＜各種通知設定＞</h4>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <h5 class="subtitle-2 mb-1">
                  浸水検知時の浸水アラートマップ上のセンサ位置の赤色保持時間の設定
                </h5>
                <p class="caption text--secondary">
                  センサが浸水を検知すると、浸水アラートマップ上のセンサ位置表示ポイントが赤色で表示されます。<br />
                  ここでは最後に浸水を検知した時間から、赤色表示を保持する時間を分単位で設定できます。<br />
                  注：分数を入力してください（例）1時間の場合、入力値は60<br />
                  ※予め推奨設定時間(60分)が入力されています。
                </p>
                <v-row dense>
                  <v-col cols="12" sm="4">
                    <v-text-field
                      v-model="item.device_alerting_interval_second"
                      :rules="rules.number"
                      :placeholder="settings.device_alerting_interval_second"
                      suffix="分"
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <h5 class="subtitle-2 mb-1">
                  浸水検知時のＬＩＮＥ通知の一時停止時間の設定
                </h5>
                <p class="caption text--secondary">
                  センサが浸水を検知すると、浸水アラートマップ上に、選択したセンサからのＬＩＮＥ通知を一定時間停止できるボタンが現れます。<br />
                  ここでは、ＬＩＮＥ通知を一時停止する時間を設定できます。注：分数を入力してください（例）３時間の場合、入力値は180<br />
                  ※予め推奨設定時間(180分) が入力されています。
                </p>
                <v-row dense>
                  <v-col cols="12" sm="4">
                    <v-text-field
                      v-model="item.user_device_notification_interval_second"
                      :rules="rules.number"
                      :placeholder="
                        settings.user_device_notification_interval_second
                      "
                      suffix="分"
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <h5 class="subtitle-2 mb-1">
                  ＬＩＮＥ通知数の制限
                </h5>
                <p class="caption text--secondary">
                  センサが浸水し始めると、30秒に1回ＬＩＮＥ通知を発信し続けます。<br />
                  ＬＩＮＥ通知数の制限のため、一定時間内に一定の通知回数を超えると一定時間通知を停止するという設定ができます。
                  （例）5分以内に、７回ＬＩＮＥ通知を発信したら、2時間（120分）ＬＩＮＥ通知を停止する。<br />
                  LINE公式アカウントの無料プランではLINE通知数1000通/月の限度数があるので、限度数に達しないようにするための設定です。<br />
                  <span style="color: red">
                    ※通知制限が必要でない場合は入力しないでください。
                  </span>
                </p>
                <v-row dense>
                  <v-col cols="12" md="4">
                    <v-text-field
                      v-model="item.notification_suppression_by_second"
                      :rules="rules.number"
                      :placeholder="settings.notification_suppression_by_second"
                      suffix="分以内に"
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      v-model="item.notification_suppression_count"
                      :rules="rules.number"
                      :placeholder="settings.notification_suppression_count"
                      suffix="回ＬＩＮＥ通知を発信したら"
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      v-model="
                        item.notification_suppression_disable_alert_second
                      "
                      :rules="rules.number"
                      :placeholder="
                        settings.notification_suppression_disable_alert_second
                      "
                      suffix="分間ＬＩＮＥ通知を停止する。"
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <p class="caption text--secondary">
                  注：ＬＩＮＥ通知は30秒に1回ということを考慮した設定をお願いします。
                </p>
              </v-col>
            </v-row>
            <v-row v-show="isDeviceAlertUseMail">
              <v-col>
                <v-row>
                  <v-col cols="12" class="pb-0">
                    <h4 class="subtitle-1">
                      ＜【メール用】通知メッセージテンプレート＞
                    </h4>
                    <p class="caption text--secondary">
                      ※こちらに入力した文章が、アラート通知メッセージとしてユーザーに送信されます。<br />
                      ※入力なしの場合、初期設定メッセージが使用されます。（メッセージ入力欄内をクリックすると初期設定メッセージが表示されます。）<br />
                      ※以下プレイスホルダ（ {timestamp}
                      など）を含めると送信時に置換されます。
                    </p>
                    <p class="body-2">
                      {timestamp}
                      と入力したところにアラート日時が表示されます。<br />
                      {name} と入力したところにセンサ名が表示されます。<br />
                      {link}
                      と入力したところに浸水検知時のメール通知の一時停止用URLが表示されます。<br />
                      {disable_time}
                      と入力したところに「浸水検知時のメールの一時停止時間の設定」が表示されます。<br />
                      （例）設定値150分の場合は2時間30分と表示
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="item.notification_subject_template_for_email"
                      label="【メール用】アラート通知メッセージの件名"
                      counter="50"
                      :placeholder="
                        settings.notification_subject_template_for_email
                      "
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-textarea
                      v-model="item.notification_message_template_for_email"
                      label="【メール用】アラート通知メッセージ"
                      counter="2000"
                      :placeholder="
                        settings.notification_message_template_for_email
                      "
                      outlined
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="
                        item.test_notification_subject_template_for_email
                      "
                      label="【メール用】テストアラート通知メッセージの件名"
                      counter="50"
                      :placeholder="
                        settings.test_notification_subject_template_for_email
                      "
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-textarea
                      v-model="
                        item.test_notification_message_template_for_email
                      "
                      label="【メール用】テストアラート通知メッセージ"
                      counter="2000"
                      :placeholder="
                        settings.test_notification_message_template_for_email
                      "
                      outlined
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <h4 class="subtitle-1">＜【メール用】各種通知設定＞</h4>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <h5 class="subtitle-2 mb-1">
                      浸水検知時のメールの一時停止時間の設定
                    </h5>
                    <p class="caption text--secondary">
                      浸水検知時のメールには、選択したセンサからのメールを一定時間停止できるURLが記載されます。<br />
                      ここでは、メールを一時停止する時間を設定できます。注：分数を入力してください（例）３時間の場合、入力値は180<br />
                      ※予め推奨設定時間(180分)が入力されています。
                    </p>
                    <v-row dense>
                      <v-col cols="12" sm="4">
                        <v-text-field
                          v-model="
                            item.user_device_notification_interval_second_for_email
                          "
                          :rules="rules.number"
                          :placeholder="
                            settings.user_device_notification_interval_second_for_email
                          "
                          suffix="分"
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <h5 class="subtitle-2 mb-1">
                      メール数の制限
                    </h5>
                    <p class="caption text--secondary">
                      センサが浸水し始めると、30秒に1回メールを発信し続けます。<br />
                      メール数の制限のため、一定時間内に一定の通知回数を超えると一定時間通知を停止するという設定ができます。
                      （例）5分以内に、７回メールを発信したら、2時間（120分）メールを停止する。<br />
                      <span style="color: red">
                        ※通知制限が必要でない場合は入力しないでください。
                      </span>
                    </p>
                    <v-row dense>
                      <v-col cols="12" md="4">
                        <v-text-field
                          v-model="
                            item.notification_suppression_by_second_for_email
                          "
                          :rules="rules.number"
                          :placeholder="
                            settings.notification_suppression_by_second_for_email
                          "
                          suffix="分以内に"
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-text-field
                          v-model="
                            item.notification_suppression_count_for_email
                          "
                          :rules="rules.number"
                          :placeholder="
                            settings.notification_suppression_count_for_email
                          "
                          suffix="回メールを発信したら"
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-text-field
                          v-model="
                            item.notification_suppression_disable_alert_second_for_email
                          "
                          :rules="rules.number"
                          :placeholder="
                            settings.notification_suppression_disable_alert_second_for_email
                          "
                          suffix="分間メールを停止する。"
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <p class="caption text--secondary">
                      注：メールは30秒に1回ということを考慮した設定をお願いします。
                    </p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed @click="close">
            キャンセル
          </v-btn>
          <v-btn depressed dark :color="mainColor" @click="save">保存</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { getDevice, putDevice } from '@/api'
import { MAIN_COLOR } from '@/constants'

export default {
  name: 'DeviceSettingEditor',
  props: {
    dialog: Boolean,
    id: String
  },
  data() {
    return {
      mainColor: MAIN_COLOR,
      loading: false,
      valid: true,
      rules: {
        number: [v => v === '' || !isNaN(v) || '数値を入力してください']
      },
      defaultItem: {
        id: '',
        name: '',
        group: '',
        notification_message_template: '',
        test_notification_message_template: '',
        device_alerting_interval_second: '',
        user_device_notification_interval_second: '',
        notification_suppression_by_second: '',
        notification_suppression_count: '',
        notification_suppression_disable_alert_second: '',
        notification_subject_template_for_email: '',
        notification_message_template_for_email: '',
        test_notification_subject_template_for_email: '',
        test_notification_message_template_for_email: '',
        user_device_notification_interval_second_for_email: '',
        notification_suppression_by_second_for_email: '',
        notification_suppression_count_for_email: '',
        notification_suppression_disable_alert_second_for_email: ''
      },
      item: {
        id: '',
        name: '',
        group: '',
        notification_message_template: '',
        test_notification_message_template: '',
        device_alerting_interval_second: '',
        user_device_notification_interval_second: '',
        notification_suppression_by_second: '',
        notification_suppression_count: '',
        notification_suppression_disable_alert_second: '',
        notification_subject_template_for_email: '',
        notification_message_template_for_email: '',
        test_notification_subject_template_for_email: '',
        test_notification_message_template_for_email: '',
        user_device_notification_interval_second_for_email: '',
        notification_suppression_by_second_for_email: '',
        notification_suppression_count_for_email: '',
        notification_suppression_disable_alert_second_for_email: ''
      },
      defaultSettings: {
        notification_message_template: '',
        test_notification_message_template: '',
        device_alerting_interval_second: 0,
        user_device_notification_interval_second: 0,
        notification_suppression_by_second: 0,
        notification_suppression_count: 0,
        notification_suppression_disable_alert_second: 0,
        notification_subject_template_for_email: '',
        notification_message_template_for_email: '',
        test_notification_subject_template_for_email: '',
        test_notification_message_template_for_email: '',
        user_device_notification_interval_second_for_email: 0,
        notification_suppression_by_second_for_email: 0,
        notification_suppression_count_for_email: 0,
        notification_suppression_disable_alert_second_for_email: 0
      },
      settings: {
        notification_message_template: '',
        test_notification_message_template: '',
        device_alerting_interval_second: 0,
        user_device_notification_interval_second: 0,
        notification_suppression_by_second: 0,
        notification_suppression_count: 0,
        notification_suppression_disable_alert_second: 0,
        notification_subject_template_for_email: '',
        notification_message_template_for_email: '',
        test_notification_subject_template_for_email: '',
        test_notification_message_template_for_email: '',
        user_device_notification_interval_second_for_email: 0,
        notification_suppression_by_second_for_email: 0,
        notification_suppression_count_for_email: 0,
        notification_suppression_disable_alert_second_for_email: 0
      }
    }
  },
  computed: {
    ...mapGetters({
      groups: 'groups'
    }),
    isDeviceAlertUseMail() {
      if (this.item.group === '') {
        return false
      }
      const group = this.groups.find(g => g.id === this.item.group)
      return (
        group.device_alert_type === 'all' || group.device_alert_type === 'mail'
      )
    }
  },
  watch: {
    async dialog(openend) {
      if (openend) {
        const device = await this.deviceById(this.id)
        for (const key in device) {
          switch (key) {
            case 'settings':
              for (const settingKey in device.settings) {
                if (device.settings[settingKey] === 0) {
                  this.item[settingKey] = ''
                  continue
                }
                this.item[settingKey] = device.settings[settingKey]
              }
              break
            case 'default_settings':
              for (const settingKey in device.default_settings) {
                this.settings[settingKey] = device.default_settings[settingKey]
              }
              break
            default:
              this.item[key] = device[key]
              break
          }
        }
      }
    }
  },
  methods: {
    ...mapActions(['updateDevice', 'setFullscreenLoading']),
    close() {
      this.$refs.form.resetValidation()
      Object.assign(this.item, this.defaultItem)
      Object.assign(this.settings, this.defaultSettings)
      this.$emit('close')
    },
    async deviceById(id) {
      this.loading = true
      let device
      try {
        const res = await getDevice(id)
        device = res.device
      } catch (e) {
        console.error(e)
        alert('サーバエラーが発生しました')
      }
      this.loading = false
      return this.convertSecondToMinute(device)
    },
    async save() {
      if (!this.$refs.form.validate()) {
        return
      }
      this.setFullscreenLoading(true)
      let data = {}
      Object.assign(data, this.item)
      try {
        const res = await putDevice(this.convertMinuteToSecond(data), 'setting')
        this.updateDevice(res.device)
      } catch (e) {
        console.error(e)
        alert('サーバエラーが発生しました')
      }
      this.close()
      this.setFullscreenLoading(false)
    },
    convertSecondToMinute(device) {
      device.settings.device_alerting_interval_second /= 60
      device.settings.user_device_notification_interval_second /= 60
      device.settings.notification_suppression_by_second /= 60
      device.settings.notification_suppression_disable_alert_second /= 60
      device.settings.user_device_notification_interval_second_for_email /= 60
      device.settings.notification_suppression_by_second_for_email /= 60
      device.settings.notification_suppression_disable_alert_second_for_email /= 60

      device.default_settings.device_alerting_interval_second /= 60
      device.default_settings.user_device_notification_interval_second /= 60
      device.default_settings.notification_suppression_by_second /= 60
      device.default_settings.notification_suppression_disable_alert_second /= 60
      device.default_settings.user_device_notification_interval_second_for_email /= 60
      device.default_settings.notification_suppression_by_second_for_email /= 60
      device.default_settings.notification_suppression_disable_alert_second_for_email /= 60
      return device
    },
    convertMinuteToSecond(device) {
      device.device_alerting_interval_second *= 60
      device.user_device_notification_interval_second *= 60
      device.notification_suppression_by_second *= 60
      device.notification_suppression_disable_alert_second *= 60
      device.user_device_notification_interval_second_for_email *= 60
      device.notification_suppression_by_second_for_email *= 60
      device.notification_suppression_disable_alert_second_for_email *= 60
      return device
    }
  }
}
</script>
